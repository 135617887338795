<template>
  <form class="flex flex-col items-start px-9.5" @submit.prevent="onSignIn">
    <div class="mb-12.5 w-full space-y-2">
      <div class="w-full">
        <div class="mb-1 font-medium">{{ $t("emailAddress") }}</div>
        <UiInput
          v-model="signInForm.email"
          class="flex-1"
          name="email"
          :disabled="disabled"
          :placeholder="$t('enterYourEmailAddress')"
        />
      </div>
      <div class="w-full">
        <div class="mb-1 font-medium">{{ $t("password") }}</div>
        <UiInput
          v-model="signInForm.password"
          class="flex-1"
          name="password"
          placeholder="••••••••••••"
          type="password"
          :disabled="disabled"
          autocomplete="current-password"
        />
      </div>
      <button
        class="font-normal underline"
        type="button"
        @click="$emit('changeForm', AuthDrawerEnum.FORGOT)"
      >
        {{ $t("forgotYourPassword") }}
      </button>
    </div>

    <UiButton
      :loading="isSubmitting"
      type="submit"
      color="primary"
      class="mb-4 w-full"
    >
      {{ $t("logIn") }}
    </UiButton>
    <UiButton
      color="primary"
      type="button"
      outlined
      class="mb-4 w-full"
      @click="$emit('changeForm', AuthDrawerEnum.REGISTER)"
    >
      {{ $t("dontHaveAnAccount") }}&nbsp;
      <span class="underline">{{ $t("signUp") }}</span>
    </UiButton>
  </form>
</template>

<script setup lang="ts">
import { useForm } from "vee-validate";
import { object, string } from "yup";
import { SignInForm } from "@/types/api/auth.types";
import { authApi } from "@/api";
import { useGeneralStore } from "@/stores/general";
import { AuthDrawerEnum, AuthDrawerType } from "~/types/components.types";
const generalStore = useGeneralStore();
const route = useRoute();
const router = useRouter();
const localePath = useLocalePathPolyfill();
const { t } = useI18n();

defineProps<{
  disabled?: boolean;
}>();

defineEmits<{
  (e: "changeForm", type: AuthDrawerType): void;
}>();

const { cartUniqueId, clientIp } = useCookiesService();

const initialSignInForm: SignInForm = {
  email: "",
  password: "",
};

const schema = object().shape({
  email: string().email().required().label(t("emailAddress")),
  password: string().required().label(t("password")),
});

const signInForm = reactive(
  JSON.parse(JSON.stringify(initialSignInForm)),
) as SignInForm;

const {
  handleSubmit: handleSubmitSignIn,
  resetForm: resetFormSignIn,
  setErrors: setErrorsSignIn,
  isSubmitting,
} = useForm({
  initialValues: initialSignInForm,
  validationSchema: schema,
});

const onSignIn = handleSubmitSignIn(async (values) => {
  const params = {
    ...values,
  };
  if (cartUniqueId.value) {
    params.cart_unique_id = cartUniqueId.value;
  }
  if (clientIp.value) {
    params.client_ip = clientIp.value;
  }
  const { data, error } = await authApi.signIn(params);

  if (!error.value) {
    resetFormSignIn();

    generalStore.setUserInfo(data.value?.data || null);
    generalStore.isSignInDrawerOpen = false;
    await Promise.all([generalStore.getCart(), generalStore.getWishList()]);

    if (route.query.redirect) {
      await router.replace(localePath(route.query.redirect as string));
    } else {
      await router.replace(localePath("/profile"));
    }
  } else {
    setErrorsSignIn(responseErrorToObjectErrors(error?.value?.data?.errors));
  }
});
</script>
